<template>
	<div>
		<v-row>
      <v-col cols="4">
        <v-btn color="primary" @click="createPost()">Создать новый шаблон поста</v-btn>
      </v-col>
			<v-col cols="12">
				<v-card>
          <v-simple-table>
            <thead>
              <tr><th>ID</th><th>Название</th><th>Дата создания</th><th>Публикаций</th></tr>
            </thead>
            <tbody v-if="postsLoaded">
              <tr v-for="(post, i) in posts" :key="i" >
                <td>{{post.id}}</td>
                <td>
                  <router-link :to="{ path: '/ad/posts/'+post.id}">{{post.title}}</router-link> <router-link :to="{ path: '/ad/posts/'+post.id}" target="_blank"><v-icon small>{{mdiOpenInNew}}</v-icon></router-link>
                </td>
                <td>{{post.created_at}}</td>
                <td>
                  {{post.publications.length}}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
import httpClient from '../../services/http.service'

export default {
  name: 'AdPosts',
  data(){
    return{
      mdiOpenInNew,
      posts: [],
      postsLoaded: false,
    }
  },
  created() {
    httpClient.get('ad-posts?expand=publications').then(({data})=>{
      this.posts = data.reverse()
      this.postsLoaded = true
    })
  },
  methods:{
    showPost(id){
      this.$router.push(`posts/${id}`)
    },
    createPost(){
      this.$router.push('posts/0')
    }
  }
}
</script>

<style scoped>

</style>
